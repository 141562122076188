<template>
  <div class="student_learn_management">
    <el-form class="zwx-form" key="trainingCourseForm" ref="trainingCourseForm" :model="trainingCourseForm" label-position="right" @submit.native.prevent>
      <div>
        <el-form-item label="培训机构：" label-width="100px">
          <el-select v-model="trainingCourseForm.agencyUid" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper"  clearable @change="queryAgencyBusinessAreaList">
            <el-option v-for="item in agencyInfoList" :key="item.rid" :label="item.unitName" :value="item.uuid"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="self-zone-code" label-width="100px" label="学员所属地区：" v-if="agencyAreaDivShow">
          <el-cascader class="zwx-select zwx-select-student" popper-class="zwx-select-popper"  ref="areaCascader" :show-all-levels="false" :props="{ checkStrictly: true }" v-model="trainingCourseForm.busZoneCode" :options="agencyAreaList" @change="$refs.areaCascader.dropDownVisible = false" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="学员所属地区：" label-width="100px" class="select-area-div" v-if="allAreaDivShow">
          <zwx-select-area class="cascader-area-biz-zone" ref="bizZoneArea" @change="bizZoneChange" @loadOver="areaIdLoadOver()" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" style="width: 230px  !important" :clearable="false" />
        </el-form-item>
        <el-form-item label="所属单位：" label-width="100px">
          <el-input class="zwx-input" v-model.trim="trainingCourseForm.unitName" style="width: 230px  !important" placeholder="请输入单位名称" clearable maxlength="50" />
        </el-form-item>
      </div>
      <div>
        <!--        <el-form-item label="培训班名称：" label-width="100px">
                  <el-input class="zwx-input" v-model.trim="trainingCourseForm.activityName" style="width: 230px  !important" placeholder="请输入" clearable maxlength="50" />
                </el-form-item>-->
        <el-form-item label="学员姓名：" label-width="100px">
          <el-input class="zwx-input" v-model.trim="trainingCourseForm.userName" style="width: 230px  !important" placeholder="请输入姓名或手机号或证件号" clearable maxlength="50" />
        </el-form-item>
        <el-form-item label="学员类别：" label-width="100px">
          <el-select v-model="trainingCourseForm.traineeType" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper"  clearable @change="val => traineeTypeChange(val)" style="width: 230px  !important">
            <el-option v-for="item in trainingActivityListFormExp.traineeTypeOptions" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="培训类型：" label-width="100px">
          <el-select v-model="trainingCourseForm.trainingType" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper"  clearable style="width: 230px  !important">
            <el-option v-for="item in trainingActivityListFormExp.trainingTypeChangeOptions" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
          </el-select>
        </el-form-item>
      </div>

      <div>
        <el-form-item label="线上课程进度：" label-width="100px">
          <el-select v-model="trainingCourseForm.studyState" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper"  style="width: 230px !important">
            <el-option label="不限" :value="''"></el-option>
            <el-option label="未开始" :value="0"></el-option>
            <el-option label="进行中" :value="1"></el-option>
            <el-option label="已完成" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="线下签到情况：" label-width="100px">
          <el-select v-model="trainingCourseForm.ifSignIn" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper" style="width: 230px !important">
            <el-option label="不限" :value="''"></el-option>
            <el-option label="未签到" :value="0"></el-option>
            <el-option label="已签到" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试情况：" label-width="100px">
          <el-select v-model="trainingCourseForm.examResultType" placeholder="请选择" clearable class="zwx-select zwx-select-student" popper-class="zwx-select-popper"  style="width: 230px  !important">
            <el-option label="待考试" :value="3"></el-option>
            <el-option label="考试通过" :value="1"></el-option>
            <el-option label="考试未通过" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <div>
          <el-form-item label="证书到期时间：" label-width="100px">
            <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 230px  !important" v-model="trainingCourseForm.certificateStartTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noBigDate($data['trainingCourseForm'], 'certificateEndTime', true)" placeholder="开始日期" />
            -
            <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 230px  !important" v-model="trainingCourseForm.certificateEndTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noSmallDate($data['trainingCourseForm'], 'certificateStartTime', true)" placeholder="结束日期" />
          </el-form-item>
          <el-form-item label="报名时间：" label-width="100px">
            <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 230px  !important" v-model="trainingCourseForm.trainingRegistStartTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noBigDate($data['trainingCourseForm'], 'trainingRegistEndTime', true)" placeholder="开始日期" />
            -
            <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 230px  !important" v-model="trainingCourseForm.trainingRegistEndTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noSmallDate($data['trainingCourseForm'], 'trainingRegistStartTime', true)" placeholder="结束日期" />
          </el-form-item>
        </div>
      </div>
      <div>
       
       
      </div>

    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryTrainingCourse(1)">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-download" @click="errorReportDownload">导出</el-button>
    </div>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" :data="tableList" border stripe tooltip-effect="light">
      <el-table-column prop="agencyName" label="培训机构" min-width="220" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.agencyName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="busZoneName" label="学员所属地区" min-width="250" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.busZoneName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="unitName" label="所属单位" min-width="250" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.unitName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="trainingActivityName" label="培训班名称" min-width="300" header-align="center" align="left"></el-table-column>
      <el-table-column prop="userName" label="学员姓名" width="100" header-align="center" align="center"></el-table-column>
      <el-table-column prop="cardType" label="证件类型" width="150" header-align="center" align="center"></el-table-column>
      <el-table-column prop="cardNo" label="证件号" width="200" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.idc }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobileTel" label="手机号" width="140" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ $zwxSm.sm4JsDecrypt(scope.row.mobileTel) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="traineeTypeName" label="学员类别" width="160" header-align="center" align="center"></el-table-column>
      <el-table-column prop="trainingTypeName" label="培训类型" width="100" header-align="center" align="center"></el-table-column>
      <el-table-column prop="registTime" label="报名时间" width="180" header-align="center" align="center"></el-table-column>
      <el-table-column prop="trainingActivityName" label="线上课程进度" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.alreadyClassHoure || 0 }}/{{ scope.row.allRequiredClassHoure }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ifSignIn" label="线下培训签到" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.ifSignIn">已签到</span>
          <span v-else>未签到</span>
        </template>
      </el-table-column>
      <el-table-column prop="examResult" label="考试情况" width="140" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ examResultMap[scope.row.examResult] || '待考试' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="certificateEndTime" label="证书到期时间" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.certificateEndTime || '-' }}</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器部分 -->
    <base-pagination :parentPage="trainingCourseForm.currentPage" :pageSize="trainingCourseForm.pageSize" :total="trainingCourseForm.total" @currentChange="queryTrainingCourse" />
  </div>
</template>

<script>
export default {
  name: 'StudentHours',
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      trainingCourseForm: {
        agencyUnitname: '', //培训机构名称
        activityName: '', //培训班名称
        userName: '', //学员信息
        unitName: '', //单位
        traineeType: '', //学员类型
        trainingType: '', //培训类型
        examResultType: undefined, //考试情况
        certificateStartTime: '', //证书到期时间
        certificateEndTime: '', //证书到期时间
        currentPage: 1,
        pageSize: 18,
        total: 0,
        busZoneCode: '', //地区
      },
      zoneCode12From: '320200000000', //默认无锡
      examResultMap: { true: '考试通过', false: '考试未通过' },
      trainingActivityListFormExp: {
        traineeTypeOptions: [],
        trainingTypeOptions: [],
        trainingTypeChangeOptions: [],
      },
      tableList: [],
      agencyAreaDivShow: false,
      allAreaDivShow: true,
      agencyInfoList: [],
      agencyAreaList: [], //培训机构经营区域集合： 区-街道
    }
  },
  activated() {
    // if (this.$route.params.fresh) this.queryTrainingCourse(1)
  },
  mounted() {
    let config = window.top.vm.$store.state.config

    this.zoneCode12From = config.empower //'320200000000'
    this.$nextTick(() => {
      this.$refs.bizZoneArea.initSearch(this.zoneCode12From)
      this.$forceUpdate()
    })

    this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('5002', data => {
      this.trainingActivityListFormExp.traineeTypeOptions = this.manyListFul(data['5002'], '')
      this.trainingActivityListFormExp.trainingTypeOptions = data['5002']
      // this.queryTrainingCourse(1)
    })
    //查询所有培训机构
    this.queryAllAgencyInfo()
  },
  methods: {
    areaIdLoadOver() {
      // this.queryAllAgencyInfo()
      this.queryTrainingCourse(1)
    },
    //查询所有的培训机构
    queryAllAgencyInfo() {
      this.$system.get(
        this.api + '/training/platform/getTdTrainingAgencyListAll-1',
        null,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.agencyInfoList = data.tdTrainingAgencyList
          }
        },
        () => {
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },
    busZoneCodeChange() { },
    queryAgencyBusinessAreaList(val) {
      let agencyUid = this.trainingCourseForm.agencyUid
      if (!agencyUid) {
        this.agencyAreaDivShow = false
        this.allAreaDivShow = true
        // 赋值当前用户所属地区
        this.$nextTick(() => {
          this.$refs.bizZoneArea.initSearch(this.zoneCode12From)
          this.$forceUpdate()
        })
      } else {
        this.agencyAreaDivShow = true
        this.allAreaDivShow = false
        this.trainingCourseForm.busZoneCode = ''
        this.agencyAreaList = []
        let codeStr = ''
        this.agencyInfoList.forEach(item => {
          if (item.uuid === agencyUid) {
            codeStr = item.areaAndAgencyBusZoneCodeListStr
          }
        })
        this.$system.get(
          this.api + '/systematic/getCascaderAreaAndNextByZoneCode12Multiple-0',
          {
            zoneCode12FromListStr: codeStr,
          },
          true,
          true,
          data => {
            if (data.type === '00') {
              this.agencyAreaList = data.areaAndNextList
              this.agencyAreaList.forEach(item => {
                item.value = item.zoneCode12
                item.label = item.zoneName
                item.children = item.childrenList
                item.children.forEach(itemm => {
                  itemm.value = itemm.zoneCode12
                  itemm.label = itemm.zoneName
                })
              })
            }
          },
          () => {
            this.$system.error({ title: '错误', message: data.mess })
          }
        )
      }
    },

    bizZoneChange() {
      this.trainingCourseForm.busZoneCode = this.$refs.bizZoneArea.getChooseArea().zoneCode12
    },
    queryTrainingCourse(currentPage) {
      // if (typeof this.trainingCourseForm.busZoneCode != 'string') {
      //   if (this.trainingCourseForm.busZoneCode.length == 1) {
      //     this.trainingCourseForm.busZoneCode = this.trainingCourseForm.busZoneCode[0]
      //   } else if (this.trainingCourseForm.busZoneCode.length == 2) {
      //     this.trainingCourseForm.busZoneCode = this.trainingCourseForm.busZoneCode[1]
      //   } else if (this.trainingCourseForm.busZoneCode.length == 0) {
      //     this.trainingCourseForm.busZoneCode = ''
      //   }
      // }
      if(!this.trainingCourseForm.agencyUid){
        this.trainingCourseForm.busZoneCode = this.$refs.bizZoneArea.getChooseArea().zoneCode12
      }else{
       
      }
      
      this.trainingCourseForm.currentPage = currentPage
      let data = {
        ...this.trainingCourseForm,
      }
      if(Array.isArray(this.trainingCourseForm.busZoneCode) && this.trainingCourseForm.busZoneCode.length>0){
        data.busZoneCode=this.trainingCourseForm.busZoneCode[this.trainingCourseForm.busZoneCode.length-1]    
      }
      if(Array.isArray(this.trainingCourseForm.busZoneCode) && this.trainingCourseForm.busZoneCode.length==0){
        data.busZoneCode=null
      }
   
      if (data.examResultType === 3) {
        data.examResultType = ''
      } else {
        if (data.examResultType === '') {
          data.examResultType = undefined
        }
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/training/platform/getAllActivityTrainingRecordList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          try {
            if (data.type === '00') {
              this.tableList = data.activityMainExpList
              this.trainingCourseForm.pageSize = data.pageSize
              this.trainingCourseForm.total = data.total
              this.tableList.forEach(item => {
                item.certificateEndTime = this.$system.formatDate(item.certificateEndTime, 'YYYY-MM-DD')
              })
            }
          } catch (error) {
          }
        },
        data => {
          this.$emit('loading', false)
          // this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },
    traineeTypeChange(data) {
      this.trainingCourseForm.trainingType = ''
      if (data) {
        this.trainingActivityListFormExp.trainingTypeChangeOptions = this.manyListFul(this.trainingActivityListFormExp.trainingTypeOptions, data)
      } else {
        this.trainingActivityListFormExp.trainingTypeChangeOptions = []
      }
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
        temp,
        obj
      data.some(e => {
        let LevelNoArr = e.codeLevelNo.split('.')
        if ((e.codeLevelNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },
    errorReportDownload() {
      let data = {
        ...this.trainingCourseForm,
      }
      if (data.examResultType == 3) {
        data.examResultType = ''
      } else {
        if (data.examResultType === '') {
          data.examResultType = undefined
        }
      }
      this.$emit('loading', true)
      this.$FormatData.exportExcel(this.api + '/training/platform/exportAllActivityTrainingRecordList-1', data,()=>{
        this.$emit('loading', false)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.bindQrCodeDialog /deep/ .el-dialog {
  width: fit-content;
  min-width: auto !important;
}

.zwx-select-student /deep/ .el-input__inner {
  width: 230px !important;
}

.select-area-div /deep/ .zwx-input {
  width: 230px !important;
}
</style>
