<template>
    <div  class="tech-service-basic-detail-index">
      <!-- 基础信息部分 -->
      <tech-basic-detail :techServiceBasic="techServiceBasic" :ifEdit="ifEdit" :isTown="true"></tech-basic-detail>
      <AdminConfigTable :ifEdit="ifEdit" v-model="userInfoExpList" :techServiceBasic="techServiceBasic" :isTown="true" @update="onAdminTableUpdate" />
      <!-- <base-headline title="资质信息" class="no-top">
        <el-button v-if="ifEdit == 1" class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="add">添加资质</el-button>
      </base-headline>
      <div v-for="(certification, index) in techServiceBasic.certificationExpList" :key="index">
        <tech-certification-detail :tdTechServiceCertificationExp="certification" @editDialog="editDialog" @delRecord="delRecord" :ifEdit="ifEdit"></tech-certification-detail>
        <hr v-if="index != techServiceBasic.certificationExpList.length - 1" class="hr-solid" style="margin-top:18px;border-bottom:0;" />
      </div> -->
      <!-- <SuperviseRecordList v-model="techServiceBasic.superviseRecordExpList" :techServiceBasic="techServiceBasic" @update="detail" />
      <PunishRecordList v-model="techServiceBasic.punishRecordExpList" :techServiceBasic="techServiceBasic" @update="detail" />
      -->
      <!-- 添加资质弹框 -->
      <base-dialog ref="qualificationsDialogRef" class="qualifications-dialog" @determine="qualificationsDialogSubmit" :title="dialogTitle" @cancel="qualificationsDialogCancel" @close="qualificationsDialogClose">
        <el-form ref="qualificationsDialogForm" class="zwx-form" :model="qualificationsDialogForm" :rules="rules">
          <div class="edit-form service-sort-css">
            <el-form-item ref="serviceSortRef" label-width="82px" label="资质类型：" prop="serviceSort">
              <cascader-single ref="diseasecascader" v-model="qualificationsDialogForm.serviceSort" :simpleCodeList="serviceSortList" :disabled="ifEditIng" @change="serviceSortChange"></cascader-single>
            </el-form-item>
            <el-form-item label-width="110px" :label="`${certificateNoLabel}：`" prop="certificateNo">
              <el-input class="zwx-input" style="width:200px !important" v-model="qualificationsDialogForm.certificateNo" placeholder="请输入" clearable />
            </el-form-item>
          </div>
          <div class="edit-form">
            <el-form-item class="date-picker-establishment" label-width="82px" label="发证日期：" prop="certificateDate">
              <el-date-picker class="zwx-date-picker" style="width:200px !important" :picker-options="$validate.noBigDateNoOverToday($data['qualificationsDialogForm'], 'invalidDate')" v-model="qualificationsDialogForm.certificateDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" />
            </el-form-item>
            <el-form-item class="date-picker-establishment" label-width="110px" label="失效日期：" prop="invalidDate">
              <el-date-picker class="zwx-date-picker" style="width:200px !important" :picker-options="$validate.noSmallDate($data['qualificationsDialogForm'], 'date', true)" v-model="qualificationsDialogForm.invalidDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" />
            </el-form-item>
          </div>
          <div class="edit-form service-project-css" :style="{ '--width': technicalServiceItemsLabel['--width'] }">
            <el-form-item ref="tbTechnicalServiceItemExpsRef" :label-width="technicalServiceItemsLabel.width" :label="`${technicalServiceItemsLabel.label}：`" prop="tbTechnicalServiceItemExps">
              <el-select class="zwx-select" v-model="qualificationsDialogForm.tbTechnicalServiceItemExps" popper-class="service-project-popper" multiple collapse-tags clearable placeholder="请选择" @focus="clickServiceProtect" @change="selectServiceProtect">
                <el-option v-for="item in serviceProjectList" :key="item.codeNo" :label="item.codeName" :value="{ value: item.codeNo, label: item.codeName }" />
              </el-select>
            </el-form-item>
          </div>
          <!-- <div class="edit-form" v-if="qualificationsDialogForm.serviceSort == '2011'" style="width:604px;display:flex;justify-content: space-between;align-items: center;">
            <el-form-item label-width="249px" label="外出开展职业健康检查工作区域范围：" prop="checkArea">
              <el-input class="zwx-input" style="width:363px !important" v-model="qualificationsDialogForm.checkArea" placeholder="请输入" clearable />
            </el-form-item>
          </div> -->
          <!-- <div class="edit-form" v-if="qualificationsDialogForm.serviceSort == '2013' || qualificationsDialogForm.serviceSort == '2014'" style="width:604px;display:flex;justify-content: space-between;align-items: center;">
            <el-form-item label-width="95px" label="实验室地址：" prop="laboratoryAddress">
              <el-input class="zwx-input" style="width:517px !important" v-model="qualificationsDialogForm.laboratoryAddress" placeholder="请输入" clearable />
            </el-form-item>
          </div> -->
          <div class="edit-form cureent-select-css">
            <el-form-item v-if="qualificationsDialogForm.serviceSort == '2012' || qualificationsDialogForm.serviceSort == '2015'" label-width="82px" label="单位级别：" prop="unitLevel">
              <cascader-options-single key="unitLevel" :options="unitLevelOptions" v-model="qualificationsDialogForm.unitLevel" dataNameFiled="label" dataCodeFiled="value" dataUpCodeFiled="childList" />
            </el-form-item>
            <el-form-item v-if="qualificationsDialogForm.serviceSort == '2014'" label-width="82px" label="资质等级：" prop="levelNo">
              <cascader-options-single key="levelNo" :options="levelNoOptions" v-model="qualificationsDialogForm.levelNo" dataNameFiled="label" dataCodeFiled="value" dataUpCodeFiled="childList" />
            </el-form-item>
            <el-form-item :label-width="['2012', '2014', '2015'].includes(qualificationsDialogForm.serviceSort) ? '110px' : '82px'" label="状态：" prop="operationStatus">
              <el-radio-group class="zwx-radio-group" v-model="qualificationsDialogForm.operationStatus">
                <el-radio class="zwx-radio" :label="true">经营</el-radio>
                <el-radio class="zwx-radio" :label="false">注销</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="!qualificationsDialogForm.operationStatus" class="date-picker-establishment" label-width="82px" label="注销日期：" prop="logoutDate">
              <el-date-picker class="zwx-date-picker" style="width:200px !important" :picker-options="$validate.noSmallDateNoOverToday($data['qualificationsDialogForm'], 'certificateDate', true)" v-model="qualificationsDialogForm.logoutDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" />
            </el-form-item>
          </div>
          <div class="edit-form">
            <el-form-item :label-width="`${annexExpsLabel[0].width || '82px'}`" :label="`${annexExpsLabel[0].label}：`" prop="tdTechServiceCertificationAnnexExps">
              <file-upload ref="coverPictureRef" accept=".png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF" @showFileList="picFileShowFileList" @success="picFileUploadSuccess" :size="20 * 1024 * 1024" sizeInfo="20M">
                <el-button class="zwx-button zwx-button-icontext-26" icon="el-icon-upload2" v-if="qualificationsDialogForm.tdTechServiceCertificationAnnexExps.length < 5">上传</el-button>
              </file-upload>
              <span style="color: color: rgba(89, 89, 89, 1);font-size: 12px;line-height: 12px;font-weight: 400;margin-left:8px;">支持png、jpeg、jpg、pdf格式文件，大小不超过20M</span>
              <div v-if="qualificationsDialogForm.tdTechServiceCertificationAnnexExps.length > 0">
                <span v-for="(item, index) in qualificationsDialogForm.tdTechServiceCertificationAnnexExps" :key="item.fileName" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)">
                  <div>
                    <i :class="item.fileIconShow" style="color:#316CF5!important"></i>
                    <a style="color:#194DFF !important;cursor:pointer;" @click="openFilePreview(item.fileName, item.filePath)">
                      {{ item.fileName }}
                    </a>
                    <span class="el-upload-list__item check" :style="item.check ? 'margin-left:5px;display:none;' : 'margin-left:5px;cursor:pointer'">
                      <i class="el-icon-upload-success el-icon-circle-check"></i>
                    </span>
                    <span class="check" :style="item.check ? 'margin-left:5px;cursor:pointer' : 'margin-left:5px;display:none;'">
                      <i class="el-icon-close" @click="deletionPositive(index)"></i>
                    </span>
                  </div>
                </span>
              </div>
            </el-form-item>
          </div>
          <div class="edit-form" v-if="['2011', '2013', '2014'].includes(qualificationsDialogForm.serviceSort)">
            <el-form-item :label-width="`${annexExpsLabel[1].width || '82px'}`" :label="`${annexExpsLabel[1].label}：`" prop="gdTechServiceCertificationAnnexExps">
              <file-upload ref="gCoverPictureRef" accept=".png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF" @showFileList="gPicFileShowFileList" @success="gPicFileUploadSuccess" :size="20 * 1024 * 1024" sizeInfo="20M">
                <el-button class="zwx-button zwx-button-icontext-26" icon="el-icon-upload2" v-if="qualificationsDialogForm.gdTechServiceCertificationAnnexExps.length < 5">上传</el-button>
              </file-upload>
              <span style="color: color: rgba(89, 89, 89, 1);font-size: 12px;line-height: 12px;font-weight: 400;margin-left:8px;">支持png、jpeg、jpg、pdf格式文件，大小不超过20M</span>
              <div v-if="qualificationsDialogForm.gdTechServiceCertificationAnnexExps.length > 0">
                <span v-for="(item, index) in qualificationsDialogForm.gdTechServiceCertificationAnnexExps" :key="item.fileName" @mouseenter="gMouseenter(index)" @mouseleave="gMouseleave(index)">
                  <div>
                    <i :class="item.fileIconShow" style="color:#316CF5!important"></i>
                    <a style="color:#194DFF !important;cursor:pointer;" @click="openFilePreview(item.fileName, item.filePath)">
                      {{ item.fileName }}
                    </a>
                    <span class="el-upload-list__item check" :style="item.check ? 'margin-left:5px;display:none;' : 'margin-left:5px;cursor:pointer'">
                      <i class="el-icon-upload-success el-icon-circle-check"></i>
                    </span>
                    <span class="check" :style="item.check ? 'margin-left:5px;cursor:pointer' : 'margin-left:5px;display:none;'">
                      <i class="el-icon-close" @click="gDeletionPositive(index)"></i>
                    </span>
                  </div>
                </span>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </base-dialog>
    </div>
  </template>
  
  <script>
  import AdminConfigTable from '../components/AdminConfigTable.vue'
  import SuperviseRecordList from '../components/superviseRecordList/index.vue'
  import PunishRecordList from '../components/punishRecordList/index.vue'
  import TechBasicDetail from '../components/TechBasicDetail'
  import TechCertificationDetail from '../components/TechCertificationDetail'
  export default {
    name: 'TownServiceBasicDetailIndex',
    components: {
      AdminConfigTable,
      SuperviseRecordList,
      PunishRecordList,
      TechBasicDetail,
      TechCertificationDetail,
    },
    data() {
      const params = JSON.parse(localStorage.getItem('service_organization.TechServiceBasicDetailIndex.params')) || {rid:0}
  
      return {
        api: this.$store.state.api,
        users: this.$store.state.users,
        folder: 'repository/technical',
        rid: params.rid || this.$route.params.rid,
        ifEdit: params.ifEdit || this.$route.params.ifEdit,
        dialogTitle: '添加资质',
        techServiceBasic: { rid: null },
        tdTechServiceCertificationExp: {
          certificateNo: '',
          certificateDate: '',
          invalidDate: '',
          fileName: '',
          serviceNames: '',
          tdTechServiceCertificationAnnexExps: [],
          gdTechServiceCertificationAnnexExps: [],
          operationStatus: false,
        },
        qualificationsDialogForm: {
          rid: '',
          checkArea: '',
          laboratoryAddress: '',
          fkByOrgId: {
            rid: '',
          },
          serviceSort: '',
          certificateNo: '',
          certificateDate: '',
          invalidDate: '',
          logoutDate: '',
          operationStatus: true,
          unitLevel: '',
          levelNo: '',
          tbTechnicalServiceItemExps: [],
          tdTechServiceCertificationAnnexExps: [],
          gdTechServiceCertificationAnnexExps: [],
        },
        qualificationsDialogFormInit: {
          rid: '',
          checkArea: '',
          laboratoryAddress: '',
          fkByOrgId: {
            rid: '',
          },
          serviceSort: '',
          certificateNo: '',
          certificateDate: '',
          invalidDate: '',
          logoutDate: '',
          operationStatus: true,
          unitLevel: '',
          levelNo: '',
          tbTechnicalServiceItemExps: [],
          tdTechServiceCertificationAnnexExps: [],
          gdTechServiceCertificationAnnexExps: [],
        },
        serviceSortList: [],
        serviceProjectList: [],
        userInfoExpList: [],
  
        unitLevelOptions: [
          { value: '1', label: '省级' },
          { value: '2', label: '地市级' },
          { value: '3', label: '区县级' },
        ],
        levelNoOptions: [
          { value: '1001', label: '甲级' },
          { value: '1002', label: '乙级' },
          { value: '1003', label: '丙级' },
        ],
        rules: {
          serviceSort: [{ required: true, message: '请选择资质类型', trigger: ['change', 'blur'] }],
          certificateNo: [{ required: true, message: '请输入证书编号', trigger: ['change', , 'blur'] }],
          // certificateDate: [{ required: true, message: '请选择发证日期', trigger: ['change', , 'blur'] }],
          invalidDate: [{ required: true, message: '请选择失效日期', trigger: ['change', , 'blur'] }],
          operationStatus: [{ required: true, message: '请选择是否经营状态', trigger: ['change', 'blur'] }],
          logoutDate: [{ required: false, message: '请选择注销日期', trigger: ['change', 'blur'] }],
          unitLevel: [{ required: false, message: '请选择单位级别', trigger: ['change', 'blur'] }],
          levelNo: [{ required: false, message: '请选择资质等级', trigger: ['change', 'blur'] }],
          tbTechnicalServiceItemExps: [{ required: true, message: '请选择服务项目', trigger: ['change', 'blur'] }],
          checkArea: [{ required: true, message: '请输入外出开展职业健康检查工作区域范围', trigger: ['change', , 'blur'] }],
          laboratoryAddress: [{ required: true, message: '请输入实验室地址', trigger: ['change', , 'blur'] }],
          tdTechServiceCertificationAnnexExps: [
            { required: true, message: '请上传附件', trigger: ['change', 'blur'] },
            { required: true, validator: this.pathValidate, trigger: ['change', 'blur'] },
          ],
          gdTechServiceCertificationAnnexExps: [
            { required: true, message: '请上传附件', trigger: ['change', 'blur'] },
            { required: true, validator: this.pathValidate, trigger: ['change', 'blur'] },
          ],
        },
        ifEditIng: false,
      }
    },
    computed: {
      breadcrumbs() {
        return this.$store.state.breadcrumbs
      },
      certificateNoLabel() {
        return (
          {
            2011: '备案编号',
            2013: '资质证书编号',
            2014: '资质证书编号',
          }[this.qualificationsDialogForm.serviceSort] || '证书编号'
        )
      },
      technicalServiceItemsLabel() {
        return (
          {
            2011: {
              '--width': '433px',
              width: '179px',
              label: '备案的职业健康检查类别',
            },
            2012: {
              '--width': '530px',
              width: '82px',
              label: '诊断项目',
            },
            2013: {
              '--width': '530px',
              width: '82px',
              label: '服务项目',
            },
            2014: {
              '--width': '530px',
              width: '82px',
              label: '服务项目',
            },
            2015: {
              '--width': '530px',
              width: '82px',
              label: '鉴定项目',
            },
          }[this.qualificationsDialogForm.serviceSort] || { '--width': '530px', width: '82px', label: '服务项目' }
        )
      },
      annexExpsLabel() {
        return [
          {
            2011: {
              width: '193px',
              label: '职业健康检查机构备案回执',
            },
            2012: {
              width: '',
              label: '资质证书',
            },
            2013: {
              width: '',
              label: '资质证书',
            },
            2014: {
              width: '',
              label: '资质证书',
            },
            2015: {
              width: '',
              label: '资质证书',
            },
          }[this.qualificationsDialogForm.serviceSort] || { label: '附件' },
          {
            2011: {
              width: '179px',
              label: '备案的职业健康检查项目',
            },
            2012: {
              width: '',
              label: '',
            },
            2013: {
              width: '221px',
              label: '经技术评审审核认定的检测项目',
            },
            2014: {
              width: '221px',
              label: '经技术评审审核认定的检测项目',
            },
            2015: {
              width: '',
              label: '',
            },
          }[this.qualificationsDialogForm.serviceSort] || { label: '附件' },
        ]
      },
    },
    created() {
      if (this.ifEdit == 1) {
        this.$emit('breadcrumb', '编辑', true)
      }
    },
    watch: {
      'qualificationsDialogForm.operationStatus'() {
        if (!this.qualificationsDialogForm.operationStatus) {
          this.rules.logoutDate[0].required = true
        } else {
          this.qualificationsDialogForm.logoutDate = ''
        }
      },
      'qualificationsDialogForm.tdTechServiceCertificationAnnexExps'() {
        if (this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps.length > 0) {
          this.$nextTick(() => {
            this.$refs.qualificationsDialogForm && this.$refs.qualificationsDialogForm.clearValidate('tdTechServiceCertificationAnnexExps')
          })
        } else {
          this.$nextTick(() => {
            this.$refs.qualificationsDialogForm && this.$refs.qualificationsDialogForm.validateField('tdTechServiceCertificationAnnexExps')
          })
        }
      },
      'qualificationsDialogForm.gdTechServiceCertificationAnnexExps'() {
        if (this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps.length >= 0) {
          this.$nextTick(() => {
            this.$refs.qualificationsDialogForm && this.$refs.qualificationsDialogForm.clearValidate('gdTechServiceCertificationAnnexExps')
          })
        } else {
          this.$nextTick(() => {
            this.$refs.qualificationsDialogForm && this.$refs.qualificationsDialogForm.validateField('gdTechServiceCertificationAnnexExps')
          })
        }
      },
    },
    mounted() {
      this.ifEdit=1
      // if (this.$route.params?.rid) {
      //   localStorage.setItem('service_organization.TechServiceBasicDetailIndex.params', JSON.stringify(this.$route.params))
      // }
      // console.log(this.$store.state)
      // localStorage.setItem('service_organization.TechServiceBasicDetailIndex.params', JSON.stringify({rid:317}))
      this.detail()
    },
    activated(){
      this.detail()
    },
    methods: {
      //资质类型change事件
      serviceSortChange(val) {
        this.qualificationsDialogForm.checkArea = ''
        this.qualificationsDialogForm.laboratoryAddress = ''
        this.$nextTick(() => {
          this.$refs.tbTechnicalServiceItemExpsRef.resetField('tbTechnicalServiceItemExps')
        })
        this.$refs.qualificationsDialogForm.clearValidate('tbTechnicalServiceItemExps')
        this.$refs.qualificationsDialogForm.clearValidate()
        // 根据选择的资质类型来获取对应的服务项目码表数据
        if (val) {
          this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes(val == 2015 ? '2012' : val, data => {
            this.serviceProjectList = this.$SimpleCodeTools.manyListFul(data[val == 2015 ? '2012' : val])
          })
          if (val == '2012') {
            this.rules.unitLevel[0].required = true
            this.$refs.qualificationsDialogForm.clearValidate('levelNo')
          } else {
            this.rules.unitLevel[0].required = false
          }
          if (val == '2014') {
            this.rules.levelNo[0].required = true
            this.$refs.qualificationsDialogForm.clearValidate('unitLevel')
          } else {
            this.rules.levelNo[0].required = false
          }
        } else {
          this.serviceProjectList = []
          this.qualificationsDialogForm.tbTechnicalServiceItemExps = ''
        }
        this.techServiceBasic.tdTechServiceCertificationExps.forEach(item => {
          if (val == item.serviceSort) {
            this.$system.notify('提示', '已存在该资质类型，请重新选择', 'info')
            this.$nextTick(() => {
              this.$refs.serviceSortRef.resetField('serviceSort')
            })
          }
        })
        this.qualificationsDialogForm.levelNo = undefined
        this.qualificationsDialogForm.unitLevel = undefined
      },
      // 点击单个删除
      delRecord(params) {
        this.$emit('loading', true)
        let data = {
          rid: params.rid,
        }
        this.$system.postJson(this.api + '/oh/repository/deleteTechnicalServiceCertification-1', data, true, true, this.delRecordSuccess, this.error)
      },
      delRecordSuccess(data) {
        if (data.type === '00') {
          this.$system.notify('成功', data.mess, 'success')
          this.detail()
        } else if (data.type === '99') {
          this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
        } else {
          this.$system.notify('错误', data.mess, 'error')
        }
        this.$emit('loading', false)
      },
      // 点击单个资质的编辑
      editDialog(data) {
        console.log(data)
        this.itemDetail(data.uuid)
      },
  
      //点击单个资质后调用查询
      itemDetail(rid) {
        this.$emit('loading', true)
        let data = {
          uuid:rid,
        }
        this.$system.post(this.api + '/basic/info/getTechnicalServiceCertification-1', data, true, true, this.itemDetailSuccess, this.error)
      },
      itemDetailSuccess(data) {
        if (data.type === '00') {
          //正在编辑
          this.ifEditIng = true
          this.dialogTitle = '编辑资质'
          data.technicalServiceCertification=data.resultInfo
          data.technicalServiceCertification.checkArea = data.technicalServiceCertification.checkArea || ''
          data.technicalServiceCertification.gdTechServiceCertificationAnnexExps = []
          this.qualificationsDialogForm = data.technicalServiceCertification
          this.qualificationsDialogForm.unitLevel = data.technicalServiceCertification.unitLevel && data.technicalServiceCertification.unitLevel + ''
          this.qualificationsDialogForm.levelNo = data.technicalServiceCertification.levelNo && data.technicalServiceCertification.levelNo + ''
          data.technicalServiceCertification.tdTechServiceCertificationAnnexExps=data.technicalServiceCertification.annexExpList
          let arrList = data.technicalServiceCertification.tdTechServiceCertificationAnnexExps.filter(item => {
            return [2003, 1003, '2003', '1003', '', undefined].includes(item.annexCode)
          })
         let gArrList = data.technicalServiceCertification.tdTechServiceCertificationAnnexExps.filter(item => {
            return [2004, 1006, '2004', '1006'].includes(item.annexCode)
          })
          this.qualificationsDialogForm.tbTechnicalServiceItemExps=data.technicalServiceCertification.itemExpList
          this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes(this.qualificationsDialogForm.serviceSort, data => {
            this.serviceProjectList = this.$SimpleCodeTools.manyListFul(data[this.qualificationsDialogForm.serviceSort])
            
            this.qualificationsDialogForm.tbTechnicalServiceItemExps = this.qualificationsDialogForm.tbTechnicalServiceItemExps.map(item => {
              return Object.assign(
                {},
                {
                  label: item.serviceName,
                  value: item.serviceNo,
                }
              )
            })
            let arr = []
            arrList.forEach(item => {
              arr.push({
                fileName: item.annexName,
                filePath: item.annexPath,
                check: false,
              })
            })
            this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps = arr
  
            let gArr = []
            gArrList.forEach(item => {
              gArr.push({
                fileName: item.annexName,
                filePath: item.annexPath,
                check: false,
              })
            })
            this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps = gArr
            this.$refs.qualificationsDialogRef.show(true)
          })
        } else if (data.type === '99') {
          this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
        } else {
          this.$system.notify('错误', data.mess, 'error')
        }
        this.$emit('loading', false)
      },
      // 点击服务项目
      clickServiceProtect() {
        if (!this.qualificationsDialogForm.serviceSort) {
          this.$system.notify('提示', '请先选择资质类型', 'info')
        }
      },
      // 服务项目的change
      selectServiceProtect(data) {
        console.log(data)
        let arrList = data
        arrList.forEach(item => {
          item.serviceNo = item.value
          item.serviceName = item.label
        })
        this.qualificationsDialogForm.tbTechnicalServiceItemExps = arrList
      },
      onAdminTableUpdate() {
        this.detail()
      },
      detail() {
        this.$emit('loading', true)
        this.rid=this.$store.state.users.fkByUnitId.uid
        let data = {
          unitUid: this.rid,
        }
        this.$system.postJson(this.api + '/basic/info/getTechServiceBasic-1', data, true, true, this.detailSuccess, this.error)
      },
      detailSuccess(data) {
        if (data.type === '00') {
          this.userInfoExpList = (data.userInfoExpList || []).map(item => {
            return {
              ...item,
             
              mobileTel: this.$zwxSm.sm4JsDecrypt(item.mobileTel),
              userNo: this.$zwxSm.sm4JsDecrypt(item.userNo),
            }
          })
          this.techServiceBasic = Object.assign({}, data.techServiceBasic)
          if(this.techServiceBasic.certificationExpList && Array.isArray(this.techServiceBasic.certificationExpList)){
  
            this.techServiceBasic.certificationExpList=this.techServiceBasic.certificationExpList.map(item=>{
              if(item.annexExpList){
                item.tdTechServiceCertificationAnnexExps=item.annexExpList
              }
              if(item.itemExpList){
                item.tbTechnicalServiceItemExps=item.itemExpList
              }
              return item
            })
           }
  
          this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes('2015', data => {
            this.serviceSortList = this.$SimpleCodeTools.manyListFul(data['2015'])
          })
        } else if (data.type === '99') {
          this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
        } else {
          this.$system.notify('错误', data.mess, 'error')
        }
        this.$emit('loading', false)
      },
      /**
       * 附件文件上传显示回调
       */
      picFileShowFileList(showList) {
        showList.forEach(item => {
          let flag = this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps.some(e => e.fileName == item)
          if (flag) {
            return
          }
          this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps.push({
            fileName: item,
            fileIconShow: this.$zwxBase.dealfilePrefix(item),
            check: false,
          })
          this.$forceUpdate()
        })
        if (showList && showList.length > 0) {
          this.generateFilesUid()
        }
      },
      /**
       * 附件文件上传显示回调
       */
      gPicFileShowFileList(showList) {
        showList.forEach(item => {
          let flag = this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps.some(e => e.fileName == item)
          if (flag) {
            return
          }
          this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps.push({
            fileName: item,
            fileIconShow: this.$zwxBase.dealfilePrefix(item),
            check: false,
          })
          this.$forceUpdate()
          
        })
        if (showList && showList.length > 0) {
          this.gGenerateFilesUid()
        }
      },
      // 获取附件文件的UID
      generateFilesUid() {
        this.$emit('loading', true)
        let data = {
          folder: this.folder,
        }
        this.$system.get(this.api + '/systematic/getAnnexUploadUid-1', data,true,true, data => {
          if (data.type === '00') {
            this.$refs.coverPictureRef.submit(data.annexUploadUid)
            this.$emit('loading', false)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        })
      },
      // 获取附件文件的UID
      gGenerateFilesUid() {
        this.$emit('loading', true)
        let data = {
          folder: this.folder,
        }
        this.$system.get(this.api + '/systematic/getAnnexUploadUid-1', data,true,true, data => {
          if (data.type === '00') {
            this.$refs.gCoverPictureRef.submit(data.annexUploadUid)
            this.$emit('loading', false)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        })
      },
      /**
       * 附件文件上传成功回调
       */
      picFileUploadSuccess(fileList) {
        console.log(222)
        console.log(fileList)
        // 文件集合
        if (this.$zwxBase.verifyIsNotBlank(this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps) && this.$zwxBase.verifyIsNotBlank(fileList)) {
          for (let i in this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps) {
            for (let j in fileList) {
              if (this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps[i].fileName === fileList[j].fileName) {
                this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps[i].filePath = fileList[j].filePath
                this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps[i].annexSuffix = fileList[j].filePath.substring(fileList[j].filePath.lastIndexOf('.') + 1)
              }
            }
          }
        }
      },
      /**
       * 附件文件上传成功回调
       */
      gPicFileUploadSuccess(fileList) {
      console.log('222',fileList)
        // 文件集合
        if (this.$zwxBase.verifyIsNotBlank(this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps) && this.$zwxBase.verifyIsNotBlank(fileList)) {
          for (let i in this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps) {
            for (let j in fileList) {
              if (this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps[i].fileName === fileList[j].fileName) {
                this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps[i].filePath = fileList[j].filePath
                this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps[i].annexSuffix = fileList[j].filePath.substring(fileList[j].filePath.lastIndexOf('.') + 1)
              }
            }
          }
        }
      },
      mouseenter(index) {
        this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps[index].check = true
        this.$forceUpdate()
      },
      mouseleave(index) {
        this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps[index].check = false
        this.$forceUpdate()
      },
      gMouseenter(index) {
        this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps[index].check = true
        this.$forceUpdate()
      },
      gMouseleave(index) {
        this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps[index].check = false
        this.$forceUpdate()
      },
      // 删除文件
      deletionPositive(index) {
        this.qualificationsDialogForm.curFilePath = ''
        this.$refs.qualificationsDialogForm.validateField('curFilePath')
        let fileName = this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps[index].fileName
        let filePath = this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps[index].filePath
        this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps.splice(index, 1)
        this.$forceUpdate()
        if (this.$zwxBase.verifyIsBlank(filePath)) {
          this.$refs.coverPictureRef.deleteIndex(index)
        }
        let fileList = this.$refs.coverPictureRef.showFileList || []
        let count = 0
        fileList.forEach(item => {
          if (fileName === item) {
            count++
          }
        })
        for (let i = 0; i < count; i++) {
          for (let j in fileList) {
            if (fileName === fileList[j]) {
              this.$refs.coverPictureRef.deleteIndex(j)
              fileList = this.$refs.coverPictureRef.showFileList || []
              continue
            }
          }
        }
      },
      // 删除文件
      gDeletionPositive(index) {
        this.qualificationsDialogForm.curFilePath = ''
        this.$refs.qualificationsDialogForm.validateField('curFilePath')
        let fileName = this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps[index].fileName
        let filePath = this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps[index].filePath
        this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps.splice(index, 1)
        this.$forceUpdate()
        if (this.$zwxBase.verifyIsBlank(filePath)) {
          this.$refs.gCoverPictureRef.deleteIndex(index)
        }
        let fileList = this.$refs.gCoverPictureRef.showFileList || []
        let count = 0
        fileList.forEach(item => {
          if (fileName === item) {
            count++
          }
        })
        for (let i = 0; i < count; i++) {
          for (let j in fileList) {
            if (fileName === fileList[j]) {
              this.$refs.gCoverPictureRef.deleteIndex(j)
              fileList = this.$refs.gCoverPictureRef.showFileList || []
              continue
            }
          }
        }
      },
      /**
       * 单个文件预览
       */
      openFilePreview(fileName, filePath) {
        let previewFileList = []
        let item = {
          fileName: fileName,
          filePath: filePath,
        }
        previewFileList.push(item)
        this.openFilePreview1(previewFileList, 0)
      },
      /**
       * 多个文件预览
       */
      openFilePreview1(fileList, index) {
        top.postMessage(
          {
            handlerType: 'previewFile',
            params: {
              fileList: fileList,
              index: index,
            },
          },
          '*'
        )
      },
      // 打开添加资质弹框
      add() {
        this.$refs.qualificationsDialogRef.show(true)
        this.dialogTitle = '添加资质'
      },
  
      /**
       * 弹框取消
       */
      qualificationsDialogCancel() {
        this.$refs.qualificationsDialogRef.show(false)
        this.qualificationsDialogClose()
      },
      /**
       * 弹框关闭
       */
      qualificationsDialogClose() {
        // 清空表单
        this.qualificationsDialogForm = Object.assign(this.qualificationsDialogForm, this.qualificationsDialogFormInit)
        this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps = []
        this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps = []
        this.ifEditIng = false
        // this.$refs.qualificationsDialogForm.resetFields()
      },
      /**
       * 弹框确定
       */
      qualificationsDialogSubmit() {
        this.$refs.qualificationsDialogForm.validate(valid => {
          if (valid) {
            this.saveData()
          }
        })
      },
      /**
       * 保存数据
       */
      saveData() {
        // this.qualificationsDialogForm.fkByOrgId.rid = this.rid
  
        const annexCodeMap = {
          1001: '职业卫生技术服务机构基本情况登记',
          1002: '职业卫生技术服务机构技术人员名单',
          1003: '相关检测资质证书',
          1004: '近2年盲样考核结果',
          1005: '10万元以上（含）仪器设备清单',
          1006: '经技术评审审核认定的检测项目',
          2001: '职业健康检查机构基本情况登记表',
          2002: '职业健康检查机构主要技术人员名单',
          2003: '江苏省职业健康检查机构备案回执',
          2004: '备案的职业健康检查项目',
        }
  
        this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps.forEach(item => {
          item.annexName = item.fileName
          item.annexPath = item.filePath
          const annexCode = {
            2011: 2003,
            2012: 1003,
            2013: 1003,
            2014: 1003,
            2015: 1003,
          }[this.qualificationsDialogForm.serviceSort]
          Object.assign(item, {
            annexCode: annexCode,
            annexCodeName: annexCodeMap[annexCode],
          })
        })
  
        this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps.forEach(item => {
          item.annexName = item.fileName
          item.annexPath = item.filePath
          const annexCode = {
            2011: 2004,
            2013: 1006,
            2014: 1006,
          }[this.qualificationsDialogForm.serviceSort]
          if (annexCode) {
            Object.assign(item, {
              annexCode: annexCode,
              annexCodeName: annexCodeMap[annexCode],
            })
          }
        })
  
        this.qualificationsDialogForm.tbTechnicalServiceItemExps.forEach(item => {
          item.serviceNo = item.value
          item.serviceName = item.label
        })
        let data = {
          ...this.qualificationsDialogForm,
          tdTechServiceCertificationAnnexExps: [
            ...this.qualificationsDialogForm.tdTechServiceCertificationAnnexExps,
            //
            ...(['2011', '2013', '2014'].includes(this.qualificationsDialogForm.serviceSort) ? this.qualificationsDialogForm.gdTechServiceCertificationAnnexExps : []),
          ],
          orgUid:this.rid
        }
        if(this.qualificationsDialogForm.uuid){
          data.uuid=this.qualificationsDialogForm.uuid
        }
        data.annexExpList=data.tdTechServiceCertificationAnnexExps
        data.itemExpList=data.tbTechnicalServiceItemExps
  
        this.$system.postJson(
          this.api + '/basic/info/addOrModifyTechnicalServiceCertification-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$refs.qualificationsDialogRef.show(false)
              this.$system.notify('成功', data.mess, 'success')
              this.detail()
            } else if (data.type === '99') {
              this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
              this.$emit('loading', false)
            } else {
              this.$notify.error({ title: '错误', message: data.mess })
              this.$emit('loading', false)
            }
          },
          () => {
            this.$emit('loading', false)
            this.$notify.error({ title: '错误', message: '网络连接失败' })
          }
        )
      },
      error() {
        this.$system.notify('错误', '网络连接失败', 'error')
        this.$emit('loading', false)
      },
    },
  }
  </script>
  
  <style lang="less" scoped>
  .tech-service-basic-detail-index {
    margin-bottom: 20px;
    /deep/ .qualifications-dialog .el-dialog {
      width: 664px !important;
      min-width: 664px !important;
    }
    .qualifications-dialog .el-dialog__footer {
      border-top: unset !important;
    }
  
    /deep/.qualifications-dialog .el-radio.zwx-radio-course > .el-radio__input + .el-radio__label {
      display: none;
    }
  
    // 弹框资质类型
    .service-sort-css,
    .cureent-select-css {
      /deep/ .el-input {
        width: 200px !important;
      }
      /deep/ .el-input__inner {
        width: 200px !important;
      }
    }
  
    // 弹框服务项目
    .service-project-css {
      /deep/ .el-input {
        width: var(--width) !important;
      }
      /deep/ .el-input__inner {
        width: var(--width) !important;
      }
      /deep/ .el-select-dropdown__item.selected {
        color: #316cf5 !important;
      }
    }
  }
  </style>
  
  <style lang="less">
  // 弹框服务项目
  .service-project-popper {
    .el-select-dropdown__item.selected {
      color: #316cf5 !important;
      &::after {
        display: none;
      }
    }
  }
  </style>
  